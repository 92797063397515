import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
const API_URL = process.env.VUE_APP_API_URL;

Vue.use(Vuex);

const loadAnchor = () => {
}

export default new Vuex.Store({
    state: {
        experiments: [],
        user: { 
            authenticated: false,
            username: '',
            discriminator: '',
            id: '',
            guilds: [],
            allGuilds: [],
            premium: 0, 
        },
        token: localStorage.getItem('arcane_token') || '',
        currentlyAuthenticating: localStorage.getItem('arcane_token') ? true : false,
        discoverPremium: false,
        limit: 0,

        needsMigration: false,
    },
    mutations: {
        updateUsername(state, username) {
            state.user.username = username;
        },
        userAuthenticated(state, userData) {
            state.user.authenticated = true;
            state.user.username = userData.username;
            state.user.discriminator = userData.discriminator;
            state.user.avatar = userData.avatar;
            state.user.id = userData.id;
            state.user.premium = userData.premium;
            state.user.guilds = userData.guilds.filter((g) => (g.permissions & 0x00000020) === 0x00000020);
            state.user.allGuilds = userData.guilds;

            if (state.user.premium === 0) loadAnchor();
        },
        userUnauthenticated(state) {
           state.user =  { 
                authenticated: false,
                username: '',
                discriminator: '',
                id: '',
                guilds: [],
                premium: 0, 
            };
            state.token = '';
            state.currentlyAuthenticating = false;
        },
        authenticating(state, authenticating) {
            state.currentlyAuthenticating = authenticating;
        },
        updateToken(state, token) {
            state.token = token;
        },
        toggleDiscoverPremium(state) {
            state.discoverPremium = !state.discoverPremium;
        },
        showPopup(state, limit) {
            state.discoverPremium = true;
            state.limit = limit || 0;
        },
        setMigrationStatus(state, bool) {
            state.needsMigration = bool;
        },
        setExperiments(state, experiments) {
            state.experiments = experiments;
        }
    },
    actions: {
        loginWithToken({commit}, code) {
            this.commit('authenticating', true);
            return new Promise((resolve) => {
                axios({url: `/oauth/callback?code=${code}`, method: 'POST' })
                .then(resp => {
                    this.commit('authenticating', false);
                    this.commit('userAuthenticated', { ...resp.data.user, ...resp.data.data });
                    this.commit('updateToken', resp.data.token);

                    localStorage.setItem('arcane_token', resp.data.token);
                // const token = resp.data.token
                // const user = resp.data.user
                // localStorage.setItem('token', token)
                // axios.defaults.headers.common['Authorization'] = token
                // commit('auth_success', token, user)
                // resolve(resp)
                    resolve(resp.data.token)
                })
              .catch(err => {
                this.commit('authenticating', false);
                localStorage.removeItem('token')
                resolve(false)
              })
            })
        },
        logoutUser(state) {
            localStorage.removeItem('arcane_token');
            state.commit('userUnauthenticated');
            loadAnchor();
        },
    },
    modules: {
    },
    getters: {
        user(state) {
            return state.user
        },
        userGuilds(state) {
            return state.user.guilds;
        },
        allUserGuilds(state) {
            return state.user.allGuilds;
        },
        userAuthenticated(state) {
            return state.user.authenticated;
        },
        premium(state) {
            return state.user.premium;
        },
        discoverPremium(state) {
            return state.discoverPremium;
        },
        limit(state) {
            return state.limit;
        },
        needsMigration(state) {
            return state.needsMigration;
        },
        experiments(state) {
            return state.experiments;
        },
    }
})
