import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueGtag from "vue-gtag"
import VueDiscordMessage from 'vue-discord-message'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import Toasted from 'vue-toasted'
Vue.use(Toasted, { className: 'custom-toast', router })

import AdSpot from './components/AdSpot.vue'
Vue.component('AdSpot', AdSpot)


// Vue.use(Input)
Vue.use(VueDiscordMessage, {
    disableFont: true,
})

Vue.use(VueGtag, {
    config: { id: "UA-155317859-1" }
}, router);

Vue.config.productionTip = false;

import Axios from 'axios'
Axios.defaults.headers.Authorization = localStorage.getItem('arcane_token');
Axios.defaults.headers.common['x-user-agent'] = 'Arcane-Bot-5.0';
Axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios = Axios;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

window.Chargebee.init({
    site: process.env.VUE_APP_CHARGEBEE_SITE,
    iframeOnly: true,
})