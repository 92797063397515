<template>
	<div class="loaders">Loading...</div>
</template>

<script>
export default {
    name: 'Loading'
};
</script>

<style scoped>
.loaders,
.loaders:before,
.loaders:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: load7 1.8s infinite ease-in-out;
	animation: load7 .9s infinite ease-in-out;
}
.loaders {
	color: #ffffff;
	font-size: 10px;
	margin: 80px auto;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.loaders:before,
.loaders:after {
	content: '';
	position: absolute;
	top: 0;
}
.loaders:before {
	left: -3.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.loaders:after {
	left: 3.5em;
}
@-webkit-keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes load7 {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
</style>
