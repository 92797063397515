<template>
    <div class="section">
        <div class='container'>
            <div class="columns">
                <div class="column has-text-centered">
                    <h1 class="title has-text-white">Woah! Spamming isn't cool. Please stop so others can have a positive experience.</h1>
                    <h1 class="title has-text-white is-size-4">You will be redirected back after the cooldown.</h1>
                    <Loading />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/Loading'
export default {
    name: 'Ratelimited',
    components: {
        Loading,
    }
}
</script>


