<template>
    <div>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-content">
                <!-- <div class="columns is-centered"> -->
                    <!-- <div class="column is-four-fifths"> -->
                        <div class="box">
                            <slot />
                        </div>
                    <!-- </div> -->
                <!-- </div> -->
            </div>
            <!-- <button class="modal-close is-large" aria-label="close"></button> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
}
</script>

<style scoped>
.modal-content {
    max-width: 500px !important;
}
</style>