import Vue from 'vue'
import VueRouter from 'vue-router'
import VueHead from 'vue-head'
import Home from '../views/Home.vue'
import store from '../store';
import Axios from 'axios';

Vue.use(VueRouter);
Vue.use(VueHead);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/features/leveling',
        name: 'Leveling Features',
        component: () => import('../views/features/Leveling.vue')
    },
    {
        path: '/features/moderation',
        name: 'Moderation Features',
        component: () => import('../views/features/Moderation.vue')
    },
    {
        path: '/features/counters',
        name: 'Counters Features',
        component: () => import('../views/features/Counters.vue')
    },
    {
        path: '/premium',
        name: 'Premium',
        component: () => import('../views/Premium.vue')
    },
    {
        path: '/commands',
        name: 'Commands',
        component: () => import('../views/Commands.vue')
    },
    {
        path: '/invite',
        name: 'Invite',
        component: () => import('../views/Invite.vue')

        // beforeEnter: () => window.location = `https://discordapp.com/oauth2/authorize?client_id=437808476106784770&scope=bot&permissions=2146958847`,
        // beforeEnter: () => window.location = `https://discord.com/oauth2/authorize?client_id=437808476106784770&scope=bot%20applications.commands&permissions=543247297791&redirect_uri=https://arcane.bot/support&response_type=code`,
    },
    {
        path: '/vote',
        name: 'Vote',
        component: () => import('../views/Vote.vue')
        // beforeEnter: () => {
        //     if (Math.random() < 0.9) window.location = `https://top.gg/bot/437808476106784770/vote`;
        //     else window.location = `https://discordbotlist.com/bots/arcane/upvote`;
        //     // else window.location = `https://top.gg/bot/437808476106784770/vote`;
        // },
    },
    {
        path: '/support',
        name: 'Support',
        alias: ['/help'],
        beforeEnter: () => window.location = `https://discord.gg/arcane`,
    },
    {
        path: '/refunds',
        name: 'Refund Policy',
        alias: ['/refundpolicy'],
        beforeEnter: () => window.location = `https://privy.gg/legal`,
    },
    {
        path: '/privacy',
        name: 'Privacy Policy',
        alias: ['/privacypolicy'],
        beforeEnter: () => window.location = `https://privy.gg/legal`,
    },
    {
        path: '/tos',
        name: 'Terms of Service',
        alias: ['/terms', '/termsofservice'],
        beforeEnter: () => window.location = `https://privy.gg/legal`,
    },
    {
        path: '/status',
        name: 'Status',
        alias: ['/stats'],
        component: () => import('../views/Status.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/authentication/Login.vue')
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/authentication/Logout.vue')
    },
    {
        path: '/authorize',
        name: 'Authorize',
        component: () => import('../views/authentication/Authorize.vue')
    },
    {
        path: '/dashboard',
        name: 'Manage Servers',
        alias: ['/d', '/manage'],
        component: () => import('../views/ManageServers.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id',
        name: 'Manage Server',
        alias: ['/d'],
        component: () => import('../views/dashboard/Guild.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/moderation',
        name: 'Moderation',
        component: () => import('../views/dashboard/modules/Moderation.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/custom-commands',
        name: 'Custom Commands',
        component: () => import('../views/dashboard/modules/CustomCommands.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/welcomer',
        name: 'Welcomer',
        component: () => import('../views/dashboard/modules/Welcomer.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/logging',
        name: 'Logging',
        component: () => import('../views/dashboard/modules/Logging.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/counters',
        name: 'Counters',
        component: () => import('../views/dashboard/modules/Counters.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/leveling',
        name: 'Leveling',
        component: () => import('../views/dashboard/modules/Leveling.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/roles',
        name: 'Roles',
        component: () => import('../views/dashboard/modules/Roles.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dashboard/:id/youtube',
        name: 'Youtube',
        component: () => import('../views/dashboard/modules/YoutubeAlerts.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/leaderboard/:id',
        name: 'Leveling Leaderboard',
        alias: ['/lb/:id'],
        component: () => import('../views/leaderboard/Leaderboard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/calculator',
        name: 'Calculator',
        component: () => import('../views/util/Calculator.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '*',
        name: '404',
        component: () => import('../views/404.vue'),
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    // do stuff
    const { requiresAuth } = to.meta;
    if (requiresAuth) {
        let token = localStorage.getItem('arcane_token');
        if (!token) {
            localStorage.setItem('to', to.path);
            next('/login');
        }
        else next();
    } else next();
})

router.afterEach(async (to, from) => {
    if (to.params.id && !to.path.includes('lb') && !to.path.includes('leaderboard')) {
        const { data } = await Axios.get(`/guilds/${to.params.id}/migratestatus`);

        if (data.requires_migration === true) store.state.needsMigration = true;
    } else {
        store.state.needsMigration = false;
    }
})

export default router
