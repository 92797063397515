<template>
    <div class="column has-text-centered" style="text-align: center;" v-if="!user || user.premium === 0">
        <!-- <ins class="adsbygoogle"
            :data-ad-client="client"
            :data-ad-slot="adSlot"
            :data-ad-format="format"
            :style="adStyle"
            :class="`${format} ${dev === 'development' ? 'borders' : 'adsbygoogle'}`"
        >
        </ins> -->
        <!-- {{adSlot}} -->
        <!-- <div v-bind:id="adSlot" :class="`${dev === 'development' ? 'borders' : ''}`"></div> -->

        <!-- <router-link to="/premium?from=ad">
            <img src="@/assets/ad.png" alt="">
        </router-link> -->

        <!-- data-ad-test="on" -->
        <ins
            v-if="slot"
            class="adsbygoogle"
            :data-ad-client="googleAdId"
            data-ad-slot="slot"
            data-full-width-responsive="true"
        ></ins>

        <ins
            v-else
            class="adsbygoogle"
            style="display:inline-block;width:728px;height:90px"
            :data-ad-client="googleAdId"
            data-ad-slot="1700765412"
            data-ad-format="horizontal"
            data-full-width-responsive="true"
        ></ins>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            dev: process.env.NODE_ENV,
            googleAdId: "ca-pub-8054338667382263"
        }
    },
    computed: {
        ...mapState(['user']),
    },
    props: {
        slot: {
            type: String,
        },
        mobileOptimized: {
            type: Boolean,
        },
        mobileOnly: {
            type: Boolean,
        }
    },
    name: 'Ad',
    mounted() {
        if (this.user.premium !== 0) return;
        setTimeout(() => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }, this.timeout || 100);
    },
    destroyed() {
        if (this.googleInit) clearTimeout(this.googleInit);
    }
}
</script>

<style scoped>
.borders {
    border: 1px solid pink !important;
    min-height: 100px;
    max-width: 1000px;
    background-color: pink;
}
</style>

