<template>
    <div>
        <Modal>
            <h1 class="has-text-white is-size-5 has-text-weight-bold is-flex">
                <figure class="image" style="margin-right: 8px;">
                    <img src="@/assets/arcane-static.png" alt="" style="height: 25px; width: 25px; margin-top: 2px" class="is-rounded">
                </figure>
                Hey Arcane subscriber!
            </h1>
            <p class="has-text-grey-light is-size-6" style="margin-top: 10px;">We need you to migrate your subscription from <a href="https://patreon.com/arcanebot" class="link">Patreon.com</a>. Don't worry, it's easy and we'll guide you through it.</p>

            <!-- <h1 class="has-text-white is-size-5 has-text-weight-semibold" style="margin-top: 15px;">Why migrate?</h1>
            <ul class="has-text-grey-light is-size-6">
                <li>- Be able to configure the bot</li>
            </ul> -->

            <h1 class="has-text-white is-size-5 has-text-weight-semibold" style="margin-top: 15px;">What if I don't?</h1>
            <ul class="has-text-grey-light is-size-6">
                <li>- Lose out on new features</li>
                <li>- You cant make any changes to the bot on the dashboard</li>
                <!-- <li>- Your subscription is pending cancellation and you'll lose perks on December 31st, 2023</li> -->
                <!-- <li>- On June 1st, 2023 your subscription will be canceled and you'll lose premium perks</li> -->
            </ul>

            <p class="has-text-grey-light is-size-6" style="margin-top: 15px;">You can read more about why we're making this change and requiring a migration on our <a href="https://medium.com/privy-gg/were-leaving-patreon-c044a3a3aea6" class="link">blog</a>. We've been asking servers to migrate since September of 2022.</p>

            <div class="buttons is-right" style="margin-top: 15px;">
                <!-- <button @click="ignore" class="button is-danger">Remind me later</button> -->
                <router-link to="/premium?migrate=1" class="button is-primary">Begin migration</router-link>
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/Modal.vue';
import { mapMutations } from 'vuex';

export default {
    name: 'Migrate',
    components: {
        Modal,
    },
    methods: {
        ...mapMutations(['setMigrationStatus']),
        ignore() {
            this.setMigrationStatus(false);
        }
    }
}
</script>