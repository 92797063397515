<template>
	<div>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <!-- v-on-clickaway="clickAway" -->
            <div class="modal-content box">
                <div class="columns">
                    <div class="column">
                        <div class="columns is-vcentered">
                            <div class="column" style="max-width: 60px;">
                                <figure class="image logo is-48x48">
                                    <img class="is-rounded" src="@/assets/arcane-static.png" draggable="false">
                                </figure>
                            </div>
                            <div class="column">
                                <h1 class="has-text-white subtitle">
                                    This is a  
                                    <span class="premium">Premium</span>
                                    feature
                                </h1>
                            </div>
                        </div>
                        <p class="has-text-grey-light">Some features are locked to premium so we can keep the bot online and running smooth for everyone. Upgrade now to get access to this feature.</p>
                        <br>
                        <div class="buttons">
                            <button class="button is-primary" @click="discoverPerks">Upgrade now</button>
                            <a class="has-text-white" style="padding-left: 8px; padding-bottom: 8px" @click="noThanks">No thanks</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { mapMutations } from 'vuex';

export default {
    name: 'PremiumPopup',
    mixins: [ clickaway ],
    methods: {
        ...mapMutations(['toggleDiscoverPremium']),
        // clickAway: function() {
        //     this.toggleDiscoverPremium();
        //     this.$gtag.event('popup-clickaway');
        //     this.$router.go();
        // },
        noThanks: function() {
            this.toggleDiscoverPremium();
            this.$gtag.event('popup-nothanks');
            // this.$router.go();
        },
        discoverPerks: function() {
            this.toggleDiscoverPremium();
            this.$gtag.event('popup-discover');
            this.$router.push('/premium');
        },
    },
    mounted() {
        this.$gtag.event('discover-popup-show');
    }
};
</script>

<style scoped>
.premium {
    color: #41b2b0;
}

.subtitle {
    margin-bottom: 3px;
}
</style>
