<template>
    <div>
        <footer class="footer" v-if="['Home', 'Terms of Service', 'Refund Policy', 'Privacy Policy', 'Premium', '', 'Invite', 'Arcane Chan', 'Leveling Features', 'Moderation Features', 'Counters Features'].includes(route) && !ratelimited">
            <div class="container">
                <div class="columns">
                    <div class="column is-two-thirds">
                        <h1 class="title has-text-white is-size-5">ARCANE.BOT</h1>
                        <p class="subtitle is-size-6 has-text-grey-light" style="line-height: 25px;">
                            Add a fresh coat of paint to your Discord server!
                            <br>
                            <br>
                            &copy; Copyright <a href="https://privy.gg" class="link">Privy.gg LLC</a> 2018-{{new Date().getFullYear()}}. All rights reserved.
                            <br>
                            Not affiliated with Discord Inc.
                        </p>
                        <div class="buttons">
                            <router-link class="button is-primary" to="/invite">Invite Arcane</router-link>
                            <a class="button is-primary" href="https://captcha.bot?utm_source=arcane_footer_invite">Invite Captcha.bot</a>
                        </div>
                    </div>

                    <div class="column">
                        <div class="content unstyled">
                           <ul>
                                <li>
                                    <router-link to="/" class="link">Home</router-link>
                                </li>
                                <li>
                                    <router-link to="/commands" class="nav-link">Commands</router-link>
                                </li>
                                <li>
                                    <router-link to="/premium" class="nav-link">Premium</router-link>
                                </li>
                                <li>
                                    <router-link to="/dashboard" class="nav-link">Dashboard</router-link>
                                </li>
                                <li>
                                    <a class="nav-link" href="https://captcha.bot?utm_source=arcane_footer">Captcha.bot</a>
                                </li>
                           </ul>
                        </div>
                    </div>
                    <div class="column">
                        <div class="content unstyled">
                           <ul>
                                <li>
                                    <router-link to="/tos" class="nav-link">Terms of Service</router-link>
                                </li>
                                <li>
                                    <router-link to="/privacy" class="nav-link">Privacy Policy</router-link>
                                </li>
                                <li>
                                    <router-link to="/refunds" class="nav-link">Refund Policy</router-link>
                                </li>
                                <li>
                                    <span data-ccpa-link="1" class="link"></span>
                                </li>
                           </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <footer class="has-text-centered" v-else-if="route !== 'Translations'">
            <p class="copyright">&copy; Copyright <a href="https://privy.gg" class="link">Privy.gg LLC</a> 2018-{{new Date().getFullYear()}}</p>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    props: ['route', 'ratelimited'],
}
</script>